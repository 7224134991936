import { MARKETPLACE_ITEMS_PER_PAGE } from '@collective/utils/shared';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Pagination } from '~/public-pages/components/pagination';
import { useMarketplace } from '~/public-pages/contexts/marketplace-context';
import { useGetBuildMarketplaceUrl } from '~/public-pages/hooks/marketplace/use-get-marketplace-url';
import { formatUrl } from '~/public-pages/router';

export const MarketplacePagination = () => {
  const { total } = useMarketplace();
  const router = useRouter();

  const buildUrl = useGetBuildMarketplaceUrl();

  const currentPage = +(router.query.page || 1);

  return (
    <Pagination
      current={currentPage}
      total={total}
      pageSize={MARKETPLACE_ITEMS_PER_PAGE}
      renderLink={(page, children) => {
        const url = formatUrl(buildUrl({ page: page > 1 ? page : undefined }));

        return <Link href={url}>{children}</Link>;
      }}
    />
  );
};
