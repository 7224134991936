import { IconButton } from '@collective/ui/lib/button/icon-button';
import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
} from '@collective/ui/lib/icon/icon';
import { ReactNode } from 'react';

export type PaginationItemProps = {
  type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next';
  page: number;
  currentPage: number;
  totalPages: number;
  renderLink: PaginationLinkProps['renderLink'];
};

export const PaginationItem = ({
  type,
  page,
  currentPage,
  totalPages,
  renderLink,
}: PaginationItemProps) => {
  if (type === 'prev') {
    const disabled = currentPage === 1;

    return (
      <PaginationLink page={page} disabled={disabled} renderLink={renderLink}>
        <IconButton
          aria-label="previous"
          isDisabled={disabled}
          icon={<IconArrowNarrowLeft />}
          variant="iconButton"
        />
      </PaginationLink>
    );
  }

  if (type === 'next') {
    const disabled = currentPage === totalPages;

    return (
      <PaginationLink page={page} disabled={disabled} renderLink={renderLink}>
        <IconButton
          aria-label="previous"
          isDisabled={disabled}
          icon={<IconArrowNarrowRight />}
          variant="iconButton"
        />
      </PaginationLink>
    );
  }

  return (
    <PaginationLink
      page={page}
      disabled={page === currentPage}
      renderLink={renderLink}
    >
      {type === 'page' ? page : '...'}
    </PaginationLink>
  );
};

type PaginationLinkProps = {
  children: ReactNode;
  page: number;
  disabled?: boolean;
  renderLink: (page: number, children: ReactNode) => ReactNode;
};

const PaginationLink = ({
  children,
  page,
  disabled,
  renderLink,
}: PaginationLinkProps) => {
  return disabled ? (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid -- to fix
    <a>{children}</a>
  ) : (
    renderLink(page, children)
  );
};
