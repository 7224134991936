import {
  IconCollectiveLongLogo,
  IconCollectiveShortLogo,
} from '@collective/ui/lib/icon/icon';
import { Box, Container, Flex } from '@collective/ui/lib/layout';
import { COLLECTIVE_WEBSITE_URL } from '@collective/utils/helpers/lib/constants';
import { useRouter } from 'next/router';

import { LanguageSwitcher } from '~/public-pages/components/language-switcher';
import { getMarketplacePushData } from '~/public-pages/hooks/marketplace/use-get-marketplace-url';
import { formatUrl } from '~/public-pages/router';

import { MarketplaceSearch } from './marketplace-search';
import { MarketplaceSubmitProjectButton } from './marketplace-submit-project-button';

export const MarketplaceHeader = () => {
  const { query } = useRouter();

  return (
    <Box backgroundColor="rythm.100" pt="44px" pb="32px">
      <Container maxW="1200px" px={{ base: '24px', xl: 0 }}>
        <Flex alignItems="center" gap={4}>
          <Box display={{ base: 'none', md: 'block' }}>
            <a href={COLLECTIVE_WEBSITE_URL}>
              <IconCollectiveLongLogo w="130px" />
            </a>
          </Box>

          <Box display={{ base: 'block', md: 'none' }}>
            <a href={COLLECTIVE_WEBSITE_URL}>
              <IconCollectiveShortLogo />
            </a>
          </Box>

          <LanguageSwitcher
            generateRoute={(language) =>
              formatUrl(getMarketplacePushData({ ...query, language }))
            }
          />

          <Box ml="auto" display={{ base: 'none', md: 'block' }}>
            <MarketplaceSubmitProjectButton />
          </Box>
        </Flex>

        <Box mt={7}>
          <MarketplaceSearch />
        </Box>
      </Container>
    </Box>
  );
};
