import { breakpoints } from '@collective/ui';
import { Flex } from '@collective/ui/lib/layout';
import { Text } from '@collective/ui/lib/typography';
import styled from '@emotion/styled';
import ReactPagination from 'rc-pagination';
import { useTranslation } from 'react-i18next';

import { PaginationItem, PaginationItemProps } from './pagination-item';

const PaginationComponent = styled(ReactPagination)`
  display: flex;
  list-style-type: none;
  align-items: center;
  width: 100%;
  margin-right: -8px;
  margin-top: var(--chakra-space-5);

  li {
    margin: 0 4px;

    &.rc-pagination-options {
      display: none;
    }

    &.rc-pagination-total-text {
      margin-right: auto;
    }

    &.rc-pagination-item {
      a {
        display: block;
        height: 22px;
        padding: 0 6px;
        border-radius: 4px;
        font-weight: 700;
        color: var(--chakra-colors-rythm-700);
      }
    }

    &.rc-pagination-item-active {
      a {
        color: white;
        background-color: var(--chakra-colors-rythm-900);
      }
    }

    &.rc-pagination-jump-prev {
      margin-left: 0;
    }

    &.rc-pagination-next {
      margin-right: 0;
    }

    &.rc-pagination-item,
    &.rc-pagination-jump-next,
    &.rc-pagination-jump-prev {
      height: 22px;
      font-weight: 700;
      color: var(--chakra-colors-rythm-700);
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    justify-content: center;
    flex-wrap: wrap;

    .rc-pagination-total-text {
      width: 100%;
      text-align: center;
      margin-bottom: var(--chakra-space-3);
    }
  }
`;

type PaginationProps = {
  total: number;
  current: number;
  pageSize: number;
  renderLink: PaginationItemProps['renderLink'];
};

export const Pagination = ({
  total,
  current,
  pageSize,
  renderLink,
}: PaginationProps) => {
  const { t } = useTranslation();

  const totalPages = Math.ceil(total / pageSize);

  return (
    <Flex>
      <PaginationComponent
        total={total}
        current={current}
        itemRender={(page, type) => (
          <PaginationItem
            currentPage={current}
            page={page}
            totalPages={totalPages}
            type={type}
            renderLink={renderLink}
          />
        )}
        hideOnSinglePage
        onChange={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
        showTitle={false}
        showTotal={(total, [from, to]) => (
          <Text color="rythm.700">
            {t('marketplace.pagination', { from, to, total })}
          </Text>
        )}
        pageSize={pageSize}
      />
    </Flex>
  );
};
